<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="건강검진 계획 기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline>
                <c-btn v-show="editable && data.heaCheckupPlanId" label="LBLREMOVE" icon="delete_forever" @btnClicked="remove" />
                <c-btn
                  v-show="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="data"
                  :mappingType="saveType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="savePlan"
                  @btnCallback="saveCallback" 
                />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-select
                  required
                  :editable="editable && !data.heaCheckupPlanId"
                  codeGroupCd="CHECK_UP_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="checkupTypeCd"
                  label="건강검진 종류"
                  v-model="data.checkupTypeCd"
                  @datachange="datachange"
                ></c-select>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-text
                  required
                  label="건강검진 계획명"
                  name="checkupName"
                  v-model="data.checkupName"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-plant
                  required
                  type="edit"
                  name="plantCd"
                  v-model="data.plantCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-datepicker
                  required
                  :range="true"
                  label="건강검진 기간"
                  name="checkupDateArray"
                  v-model="data.checkupDateArray"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <!-- 병원 -->
                <c-multi-select
                  :isArray="false"
                  :comboItems="comboItems"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="hospitalId"
                  label="병원"
                  v-model="data.hospitalId"
                ></c-multi-select>
              </div>
                  <!-- @datachange="datachangeHosptial" -->
              <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
                <c-text
                  :disabled="true"                  
                  label="병원위치"
                  name="hospitalLocation"
                  v-model="data.hospitalLocation"
                >
                </c-text>
              </div> -->
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-card title="검진 대상자 조회/추가" class="cardClassDetailForm">
            <template slot="card-button">
              <!-- <q-chip 
                square clickable 
                size="md"
                color="light-green-8" 
                text-color="white"
                icon="contact_support"
                label="범례"
                style="height:26px;margin-right:2px !important;"
                class="q-ml-sm">
                <q-popup-proxy>
                  <q-banner>
                    <b>
                    ※ 유해인자 취급부서 관련 
                    </b>
                    <br><br>
                    <q-chip color="red" />
                     화학물질 포함된 특검대상유해인자 규제항목 대상 및 함유량 1%이상 유해인자 취급부서
                     <br>
                    <q-chip color="orange" />
                     화학물질 포함이 안됐지만 특검관련이 있는 유해인자 취급부서 
                  </q-banner>
                </q-popup-proxy>
              </q-chip> -->
            </template>
            <template slot="card-detail">
              <div class="col-12">
                <c-tag 
                  :editable="editable"
                  icon="person_outline"
                  itemText="deptName"
                  itemValue="check"
                  label="LBLDEPT"
                  name="deptList" 
                  v-model="searchParam.deptList"
                  @addTag="openDept"
                  @removeTag="removeDept"
                />
                <!-- <c-text
                  :editable="editable"
                  :disabled="true"
                  :afterIcon="[
                    { name: 'search', click: true, callbackName: 'searchDept', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeDept', color: 'red' }
                  ]"
                  label="LBLDEPT"
                  name="deptNames"
                  v-model="deptNames"
                  @searchDept="openDept"
                  @removeDept="removeDept">
                </c-text> -->
              </div>
              <div class="col-12">
                <c-text
                  label="사용자"
                  name="userName"
                  v-model="searchParam.userName">
                </c-text>
              </div>
              <!-- <div class="col-12">
                <c-text
                  v-show="data.checkupTypeCd === 'CUTC000001'"
                  :editable="editable"
                  :disabled="true"
                  :afterIcon="[
                    { name: 'search', click: true, callbackName: 'searchHazard', color: 'teal' },
                    { name: 'close', click: true, callbackName: 'removeHazard', color: 'red' }
                  ]"
                  label="유해인자"
                  name="hazardNames"
                  v-model="hazardNames"
                  @searchHazard="openHazard"
                  @removeHazard="removeHazard">
                </c-text>
              </div>
              <div class="col-12">
                <c-tag 
                  :editable="editable"
                  :isAdd="false"
                  icon="person_outline"
                  itemText="deptName"
                  itemValue="check"
                  label="유해인자 관련 취급부서"
                  name="deptName" 
                  v-model="searchParam.deptHazardList"
                  @removeTag="removeTag"
                />
              </div> -->
              <div class="col-12">
                <c-table
                  ref="table"
                  title="사용자 목록"
                  tableId="table"
                  :columns="grid.columns"
                  :gridHeight="grid.height"
                  :merge="grid.merge"
                  :data="grid.data"
                  :columnSetting="false"
                  :isFullScreen="false"
                  :usePaging="true"
                  :expandAll="true"
                  selection="multiple"
                  rowKey="userId"
                >
                  <!-- 버튼 영역 -->
                  <template slot="table-button">
                    <q-btn-group outline >
                      <c-btn
                        label="LBLSEARCH"
                        icon="search" @btnClicked="getTargetUserList"/>
                    </q-btn-group>
                  </template>
                </c-table>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1" style="margin: auto 0; line-height: 35px; text-align:center;">
          <c-btn v-if="editable" label="선택추가" icon="arrow_forward_ios" color="blue-7" @btnClicked="selectAdd" />
          <c-btn v-if="editable" label="전체추가" icon="arrow_forward_ios" color="black" @btnClicked="allAdd" /><br><br>
          <c-btn v-if="editable" label="선택제외" icon="arrow_back_ios" color="red" @btnClicked="selectDelete" />
          <c-btn v-if="editable" label="전체제외" icon="arrow_back_ios" color="black" @btnClicked="allDelete" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <c-table
            topBorderClass="topcolor-lightblue"
            ref="table2"
            title="검진대상자 목록"
            tableId="table2"
            :columns="grid2.columns"
            :gridHeight="grid2.height"
            :data="data.checkupResultModelList"
            :merge="grid2.merge"
            :columnSetting="false"
            :isFullScreen="false"
            :usePaging="true"
            :expandAll="true"
            selection="multiple"
            rowKey="heaCheckupResultId"
          >
            <template slot="table-button">
              <q-btn-group outline >
                <!-- <q-chip 
                  square clickable 
                  size="md"
                  color="light-green-8" 
                  text-color="white"
                  icon="contact_support"
                  label="범례"
                  style="height:26px;margin-right:2px !important;"
                  class="q-ml-sm">
                  <q-popup-proxy>
                    <q-banner>
                      <b>
                      ※ 유해인자 관련
                      </b>
                      <br><br>
                      <q-chip color="blue-7" />
                        화학적인자
                        <br>
                      <q-chip color="deep-purple-6" />
                        물리적인자
                        <br>
                      <q-chip color="green-7" />
                        분진
                        <br>
                      <q-chip color="black" />
                        야간작업
                    </q-banner>
                  </q-popup-proxy>
                </q-chip> -->
                <c-btn label="검진기관제출용" icon="print" @btnClicked="submissionPrint" />
              </q-btn-group>
            </template>
            
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'hazardNames'">
               <span v-if="props.row.hazardNames1" class="text-blue-7">
                {{ props.row.hazardNames1 + '/' }} 
                </span>
                <span v-if="props.row.hazardNames2" class="text-deep-purple-6">
                  {{ props.row.hazardNames2 + '/' }} 
                </span>
                <span v-if="props.row.hazardNames3" class="text-green-7">
                  {{ props.row.hazardNames3 + '/'}} 
                </span>
                <span v-if="props.row.hazardNames4" class="text-black">
                  {{ props.row.hazardNames4 }} 
                </span>
            </template>
          </template>
          </c-table>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import { uid } from 'quasar'
import XLSX from 'xlsx'
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: "check-up-plan",
  props: {
    data: {
      type: Object,
      default: () => ({
        heaCheckupPlanId: '',
        plantCd: '',
        checkupName: '',
        checkupTypeCd: null,
        checkupStartDate: '',
        checkupEndDate: '',
        regUserId: '',
        chgUserId: '',
        checkupResultModelList: [],
        deleteCheckupResultModelList: [],

        // prototype
        hospitalId: '',
        hospitalLocation: '',
      }),
    },
  },
  data() {
    return {
      editable: true,
      searchParam: {
        userName: '',
        deptList: [],
        checkupTypeCd: null,
      },
      tempList: [],
      deptNames: '', // 부서 복수로 나타내기
      hazardNames: '', // 유해인자 복수로 나타내기
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '90%',
        param: {},
        closeCallback: null,
      },
      comboItems:[],
      grid: {
        merge: [
          { index: 0, colName: 'deptCd' },
        ],
        columns: [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: 'LBLUSER',
            align: 'center',
            sortable: false,
          },
        ],
        height: '460px',
        data: [],
      },
      grid2: {
        merge: [
        ],
        columns: [
        ],
        height: '680px',
        data: [],
      },
      getUrl: '',
      insertUrl: '',
      saveUrl: 'transactionConfig.hea.checkup.plan.insert.url',
      updateUrl: '',
      deleteUrl: '',
      targetUserUrl: '',
      isSave: false,
      saveType: 'POST',
      isSpecial: false,
      printUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {},
  mounted() {
    this.init();
  },
  beforeDestroy() {},
  computed: {},
  watch: {
    'data'() {
      this.datachange();
    },
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      
      this.targetUserUrl = selectConfig.hea.checkup.plan.target.users.url;
      this.insertUrl = transactionConfig.hea.checkup.plan.insert.url;
      this.updateUrl = transactionConfig.hea.checkup.plan.update.url;
      this.deleteUrl = transactionConfig.hea.checkup.plan.delete.url;
      
      this.setHospital();
      this.datachange();
    },
    getTargetUserList() {
      this.searchParam.checkupTypeCd = this.data.checkupTypeCd;
      this.$http.url = this.targetUserUrl;
      this.$http.type = 'POST';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
        
        this.$refs['table'].selected = [];
      },);
    },
    setHospital() {
      this.$http.url = selectConfig.hea.hospital.list.url;
      this.$http.type = 'GET';
      this.$http.param = {
        useFlag: 'Y',
      };
      this.$http.request((_result) => {
        this.$_.forEach(_result.data, _item => {
          this.comboItems.push({
            code: _item.hospitalId,
            codeName: _item.hospitalName,
            hospitalLocation: _item.hospitalLocation,
          })
        })
      },);
    },
    setHeader() {
      this.grid2.columns = [];
      if (this.isSpecial) {
        this.grid2.merge = [
          { index: 0, colName: 'deptCd' },
        ]
        this.grid2.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width:10%',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자',
            align: 'center',
            style: 'width:10%',
            sortable: false,
          },
          {
            name: 'hazardNames',
            field: 'hazardNames',
            label: '관련유해인자',
            type: 'custom',
            style: 'width:60%',
            align: 'left',
            sortable: false,
          },
          {
            name: 'checkupCycleName',
            field: 'checkupCycleName',
            label: '검진주기',
            style: 'width:10%',
            align: 'center',
            sortable: false,
          },
          {
            name: 'recentCheckupDate',
            field: 'recentCheckupDate',
            label: '최근검진일',
            style: 'width:10%',
            align: 'center',
            sortable: false,
          },
        ]
      } else {
        this.grid2.merge = [
          { index: 0, colName: 'deptCd' },
        ]
        this.grid2.columns = [
          {
            name: 'deptName',
            field: 'deptName',
            label: 'LBLDEPT',
            align: 'center',
            style: 'width:25%',
            sortable: false,
          },
          {
            name: 'userName',
            field: 'userName',
            label: '대상자',
            align: 'center',
            style: 'width:25%',
            sortable: false,
          },
          {
            name: 'checkupCycleName',
            field: 'checkupCycleName',
            label: '검진주기',
            style: 'width:25%',
            align: 'center',
            sortable: false,
          },
          {
            name: 'recentCheckupDate',
            field: 'recentCheckupDate',
            label: '최근검진일',
            style: 'width:25%',
            align: 'center',
            sortable: false,
          },
        ]
      }
      
    },
    savePlan() {
      if (this.data.heaCheckupPlanId) {
        this.saveUrl = this.updateUrl;
        this.saveType = 'PUT';
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = 'POST';
      }
      this.$refs['editForm'].validate().then(_result => {
        if (_result) { 
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.data.regUserId = this.$store.getters.user.userId
              this.data.chgUserId = this.$store.getters.user.userId
              
              if (this.data.checkupDateArray && this.data.checkupDateArray.length > 0) {
                this.data.checkupStartDate = this.data.checkupDateArray[0];
                this.data.checkupEndDate = this.data.checkupDateArray[1];
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (!this.data.heaCheckupPlanId) {
        this.$emit('changeStatus', result.data, this.data.checkupTypeCd)
      } else {
        this.$emit('getDetail')
      }
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: '삭제하시겠습니까? 계획과 결과의 모든 정보가 삭제됩니다.',
        // TODO : 필요시 추가하세요.
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.data.heaCheckupPlanId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup', 'REMOVE');  
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    openDept() {
      this.$http.url = selectConfig.sai.hazard.list.dept.checkup.url;
      this.$http.type = 'GET';
      this.$http.param = {
        plantCd: this.data.plantCd
      }
      this.$http.request((_result) => {
        this.popupOptions.title = '관련 부서 추가';
        this.popupOptions.param = {
          plantCd: this.data.plantCd,
          depts: _result.data.hazardFactorsDeptList ? _result.data.hazardFactorsDeptList : [],
          oneMoreDepts: _result.data.hazardFactorsDeptOneMoreList ? _result.data.hazardFactorsDeptOneMoreList : [],
          isSpecial: this.isSpecial,
        }
        this.popupOptions.target = () => import(`${'./deptMultiHazardPop.vue'}`);
        this.popupOptions.width = '70%';
        this.popupOptions.visible = true;
        this.popupOptions.closeCallback = this.closeDeptPopup;
      },);
    },
    closeDeptPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      let sum = 0;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          if (this.$_.findIndex(this.searchParam.deptList, { deptCd: item.deptCd }) === -1) {
            this.searchParam.deptList.push({
              deptCd: item.deptCd,
              deptName: item.deptName,
            })
            sum++;
            if (sum === 1) {
              this.deptNames = item.deptName;
            } else if (item.length !== sum) {
              this.deptNames += ', ' + item.deptName  
            }
          }
        })
      }
    },
    removeDept(item) {
      this.searchParam.deptList = this.$_.reject(this.searchParam.deptList, item)
    },
    datachange() {
      if (this.data.checkupTypeCd === 'CUTC000002') {
        // this.searchParam.deptList = [];
        this.isSpecial = true;
        this.setHeader();
      } else if (this.data.checkupTypeCd === 'CUTC000001') {
        // this.searchParam.deptList = [];
        this.isSpecial = false;
        this.setHeader();
      }
    },
    selectAdd() {
      this.$refs['table2'].selected = [];
      let selectData = this.$refs["table"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '추가할 대상자를 지정하세요.', // 추가할 대상자를 먼저 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } 
      this.$_.forEach(selectData, (item) => {
        if (this.$_.findIndex(this.data.checkupResultModelList, { userId: item.userId }) === -1) {
          this.data.checkupResultModelList.push({
            mergeGroup: item.deptCd + ' / ' + item.userId,
            heaCheckupResultId: uid(),
            userId: item.userId,
            userName: item.userName,
            deptCd: item.deptCd,
            deptName: item.deptName,
            checkupCycleName: item.checkupCycleName,
            recentCheckupDate: item.recentCheckupDate,
            hazardNames1: item.hazardNames1,
            hazardNames2: item.hazardNames2,
            hazardNames3: item.hazardNames3,
            hazardNames4: item.hazardNames4,
            editFlag: 'C',
          })
        }
        // this.grid.data = this.$_.reject(this.grid.data, item)
      })
    },
    allAdd() {
      this.$refs['table2'].selected = [];
      if (this.grid.data.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '추가할 대상자가 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } 
      this.$_.forEach(this.grid.data, item => {
        if (!this.grid.data) {
          this.grid.data = [];
        }
        if (this.$_.findIndex(this.data.checkupResultModelList, { userId: item.userId }) === -1) {
          this.data.checkupResultModelList.push({
            mergeGroup: item.deptCd + ' / ' + item.userId,
            heaCheckupResultId: uid(),
            userId: item.userId,
            userName: item.userName,
            deptCd: item.deptCd,
            deptName: item.deptName,
            checkupCycleName: item.checkupCycleName,
            recentCheckupDate: item.recentCheckupDate,
            hazardNames1: item.hazardNames1,
            hazardNames2: item.hazardNames2,
            hazardNames3: item.hazardNames3,
            hazardNames4: item.hazardNames4,
            editFlag: 'C',
          })
        }
        // this.grid.data = this.$_.reject(this.grid.data, item)
      })
    },
    selectDelete() {
      this.$refs['table'].selected = [];
      let selectData = this.$refs["table2"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '제외할 대상자를 선택하세요.', // 제외할 대상자를 선택하세요.
          type: 'warning', // success / info / warning / error
        });
      } 
      this.$_.forEach(selectData, (item) => {
        if (!this.grid.data) {
          this.grid.data = [];
        }
        if (!this.data.deleteCheckupResultModelList) {
          this.data.deleteCheckupResultModelList = [];
        }
        // if (this.$_.findIndex(this.grid.data, { userId: item.userId }) === -1) {
        //   this.grid.data.push(item);
        // }
        if (this.$_.findIndex(this.data.deleteCheckupResultModelList, { heaCheckupResultId: item.heaCheckupResultId }) === -1
          && item.editFlag !== 'C') {
            this.data.deleteCheckupResultModelList.push(item)
        }
        this.data.checkupResultModelList = this.$_.reject(this.data.checkupResultModelList, item)
      })
    },
    allDelete() {
      this.$refs['table'].selected = [];
      if (this.data.checkupResultModelList.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '제외할 대상자가 없습니다.',
          type: 'warning', // success / info / warning / error
        });
      } 
      this.$_.forEach(this.data.checkupResultModelList, item => {
        if (!this.grid.data) {
          this.grid.data = [];
        }
        if (!this.data.deleteCheckupResultModelList) {
          this.data.deleteCheckupResultModelList = [];
        }
        // if (this.$_.findIndex(this.grid.data, { userId: item.userId }) === -1) {
        //   this.grid.data.push(item);
        // }
        if (this.$_.findIndex(this.data.deleteCheckupResultModelList, { heaCheckupResultId: item.heaCheckupResultId }) === -1
          && item.editFlag !== 'C') {
            this.data.deleteCheckupResultModelList.push(item)
        }
        this.data.checkupResultModelList = this.$_.reject(this.data.checkupResultModelList, item)
      })
    },
    /* eslint-disable no-unused-vars */ 
    submissionPrint() {
      if (this.data.checkupResultModelList.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: '대상자가 없습니다.',
          type: 'warning', // success / info / warning / error
        });
        return;
      } else {
        let printData = [];
        this.$_.forEach(this.data.checkupResultModelList, _item => {
          printData.push({
            // '사이트(사업장)': _item.plantName,
            'LBLDEPT': _item.deptName,
            '입사일자': _item.enterDate,
            'LBLNAME': _item.userName,
            '유해인자목록': _item.hazardNames
          })
        })
        const worksheet = XLSX.utils.json_to_sheet(printData);
        worksheet["!cols"] = [
          // { wpx: 100 },
          { wpx: 110 },
          { wpx: 80 },
          { wpx: 70 },
          { wpx: 500 },
        ]
        const workBook = {
          SheetNames: ['Sheet1'], // sheet name
          Sheets: {
            'Sheet1': worksheet,
          }
        };
        XLSX.writeFile(workBook, '검진기관제출용(' + this.data.checkupName + ').xlsx');
      }
    },
    datachangeHosptial(data) {
      let idx = this.$_.findIndex(this.comboItems, {code: data.value })
      if (idx > -1) {
        this.data.hospitalLocation = this.comboItems[idx].hospitalLocation;
      }
    },
  },
};
</script>
